import React from "react"
import cx from "classnames"
import "./hero.scss"

const Hero = props => {
  const { className, data } = props

  return (
    <section className={cx("heroJobs", className && className)}>
      <div className="heroJobs_banner">
        <div className={cx("heroContent")}>
          <h1 className="heroContent_title">{data.title.text}</h1>
          <a
            className="heroContent_link"
            href={data.pdf_link.url}
            target="_blank"
          >
            {data.pdf_label}
          </a>
        </div>
        <div className="heroMedia">
          <video
            className="heroMedia_video"
            controls
            playsInline
            src={`${data.side_video.url}#t=0.001`}
            style={{ background: "#f2f5f9" }}
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
