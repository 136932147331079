import React from "react"
import "./module.scss"

const Module = props => {
  const { title, list } = props

  return (
    <section className="moduleWrapper">
      <div className="moduleWrapper_content">
        <h2 className="moduleTitle">{title.text}</h2>
        <div className="moduleList">
          {list.map((item, index) =>
            item.submodule_link ? (
              <a
                className="moduleList_itemClickable"
                href={item.submodule_link.url}
              >
                <figcaption key={`module-${index}`} className="moduleList_item">
                  <img
                    className="moduleIcon"
                    src={item.submodule_icon.url}
                    alt={item.submodule_icon.alt}
                  />
                  <h3 className="moduleName">{item.submodule_name}</h3>
                </figcaption>
              </a>
            ) : (
              <figcaption key={`module-${index}`} className="moduleList_item">
                <img
                  className="moduleIcon"
                  src={item.submodule_icon.url}
                  alt={item.submodule_icon.alt}
                />
                <h3 className="moduleName">{item.submodule_name}</h3>
              </figcaption>
            )
          )}
        </div>
      </div>
    </section>
  )
}

export default Module
