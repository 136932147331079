import React, { useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import LanguageContext from "../contexts/language"

const Footer = () => {
  const { lang } = useContext(LanguageContext)

  return (
    <footer id="footer" role="contentinfo">
      <div className="headquarters">
        <div className="row">
          <div className="col-md-6 col-md-offset-3">
            <figure className="text-center">
              <img
                src="/img/logo-foris-blanco.svg"
                alt="FORIS"
                style={{ height: 70 }}
              />
            </figure>

            <div className="social text-center">
              <ul>
                <li>
                  <a
                    href="https://twitter.com/foris"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon size="lg" icon={["fab", "twitter"]} />
                    <span className="visuallyhidden">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/forisai/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon size="lg" icon={["fab", "facebook"]} />
                    <span className="visuallyhidden">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/foris/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon size="lg" icon={["fab", "linkedin"]} />
                    <span className="visuallyhidden">LinkedId</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCoPkrVlF3Iv9Zuka4H-Aybg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon size="lg" icon={["fab", "youtube"]} />
                    <span className="visuallyhidden">YouTube</span>
                  </a>
                </li>
              </ul>
            </div>

            <p className="text-center footerfont">
              {lang === "es" &&
                "Argentina - Chile - Colombia - El Salvador - España - México - Paraguay - Perú - República Dominicana"}
              {lang === "en" &&
                "Argentina - Chile - Colombia - El Salvador - Spain - Mexico - Paraguay - Peru - Dominican Republic"}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-6 col-xs-offset-0 col-md-2 col-md-offset-4">
            <p className="text-right footerfont">
              <FontAwesomeIcon icon={["fas", "phone"]} />
              <a style={{ marginLeft: 7 }} href="tel:+56227690143">
                +56 2 2769 0143
              </a>
            </p>
          </div>
          <div className="col-xs-6 col-md-2">
            <p className="text-left footerfont">
              <FontAwesomeIcon icon={["fas", "envelope"]} />
              <a style={{ marginLeft: 7 }} href="mailto:info@foris.ai">
                info@foris.ai
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
