import React from "react"
import cx from "classnames"
import "./team.scss"

const Team = props => {
  const { className, data } = props
  const [image, setImage] = React.useState(data.primary.team_image)

  const validateImage = () =>
    setImage(
      window.innerWidth <= 991.98
        ? data.primary.team_image_mobile
        : data.primary.team_image
    )

  React.useEffect(() => {
    validateImage()
    window.addEventListener(`resize`, validateImage)
    return () => window.removeEventListener(`resize`, validateImage)
  }, [])

  return (
    <section className={cx("team", className && className)}>
      <article className="team_content">
        <h2 className="contentTitle">{data.primary.team_title.text}</h2>
        <p className="contentDescription">{data.primary.team_description}</p>
      </article>
      <figure className="team_figure">
        <img className="teamImage" src={image.url} alt={image.alt || "logo"} />
      </figure>
    </section>
  )
}

export default Team
