import React, { useState } from "react"
import cx from "classnames"
import { headerItems } from '../header/Header'
import "./headerMobile.scss"

const HeaderMobile = (props) => {
  const { className, urlPrefix } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className={cx(className && className, 'headerMobile')}>
      <button className="headerMobile_button" onClick={()=>{setOpen(true)}}>
        <div className="hamburgerLine"/>
        <div className="hamburgerLine"/>
        <div className="hamburgerLine"/>
      </button>
      {open && <div className="headerMobile_overlay">
        <section className="menuMobile">
          <div className="menuMobile_header">
            <img
              className="headerLogo"
              src="/img/logo-foris.svg"
              alt="Foris"
            />
            <button className="headerClose" onClick={()=>{setOpen(false)}}>
              <img
                className="headerClose_icon"
                src="/img/cross.svg"
                alt="Close"
              />
            </button>
          </div>
          <ul className="menuMobile_body">
            {headerItems?.map((item, index) => {
              if(item?.items) {
                return (
                  <li className="menuItem menuItem__category" key={`menuItem${index}`}>
                    <span className="menuItem_subtitle">{item?.label}</span>
                    {item?.items?.map((subItem, subIndex)=> (
                      <a className="menuItem_link" key={`menuItem_link${subIndex}`} href={`${urlPrefix}${subItem?.href}`}>
                        {subItem?.label}
                      </a>
                    ))}
                  </li>
                )
              } else {
                return (
                  <li className="menuItem" key={`menuItem${index}`}>
                    <a className="menuItem_link" href={`${urlPrefix}${item?.href}`}>
                      {item?.label}
                    </a>
                  </li>
                )
              }
            })}
          </ul>
        </section>
      </div>}
    </div>
  )
}

export default HeaderMobile
